import React from 'react'
import Validate from '../components/validation.js';
import FieldText from '../components/field_text.js';
import FieldEmail from '../components/field_email.js';
import FieldTextArea from '../components/field_textarea.js';
import FieldErrorMessage from '../components/field_error_message.js';
import ReCAPTCHA from "react-google-recaptcha";

const SITE_KEY = "6LdQ8okaAAAAAJ1TtdSa9vX_L45LiJZlsmvfcYGw";

export default class FormContact extends React.Component {
    
    constructor () {
        super();
        this.state = {
            formIsValid: false,
            formControls: {
                name: {
                    type: 'text',
                    value: '',
                    placeholder: '',
                    valid: false,
                    touched: false,
                    validationRules: {
                        isRequired:  true,
                    },
                    errorMessages: ''
                },
                email: {
                    type: 'email',
                    value: '',
                    placeholder: '',
                    valid: false,
                    touched: false,
                    validationRules: {
                        isRequired: true,
                        isEmail: true
                    },
                    errorMessages: ''
                },
                phone: {
                    type: 'text',
                    value: '',
                    placeholder: '',
                    valid: false,
                    touched: false,
                    validationRules: {
                        isRequired: true
                    },
                    errorMessages: ''
                },
                enquiry: {
                    type: 'text',
                    value: '',
                    placeholder: '',
                    valid: false,
                    touched: false,
                    validationRules: {
                        isRequired: true
                    },
                    errorMessages: ''
                },
            },
            recaptcha: {
                callback: "not fired",
                value: "[empty]",
                load: false,
                expired: "false",
                recaptchaLoaded: false
            }
        };
        this.recaptchaRef = React.createRef();
    }

	changeHandler = event => {
        const name = event.target.name;
        const value = event.target.value;

        const updatedControls = {
            ...this.state.formControls
        };
        const updatedFormElement = {
            ...updatedControls[name]
        };
        updatedFormElement.value = value;
        updatedFormElement.touched = true;

        let validateFormElement = [];
        validateFormElement = Validate(value, updatedFormElement.validationRules);

        updatedFormElement.valid = validateFormElement.isValid;
        updatedFormElement.errorMessages = validateFormElement.errorMessages;

        updatedControls[name] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedControls) {
            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
        }

        this.setState({
            formControls: updatedControls,
            formIsValid: formIsValid
        });
    }

    submitHandler = async e => {
        e.preventDefault();
        e.persist();

        const token = await this.recaptchaRef.current.executeAsync();
        this.setState({ recaptcha: { value: token } });
        // this.setState({ recaptcha: { value: null } });
        //console.log(this.state.recaptcha.value);

        const form = e.target;
        const formData = {};

        for (let formElementId in this.state.formControls) {
            formData[formElementId] = this.state.formControls[formElementId].value
        }

        const encode = (formData) => {
            return Object.keys(formData)
                .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(formData[key]))
                .join("&");
        }

        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ 
                "form-name": form.getAttribute("name"),
                "g-recaptcha-response": this.state.recaptcha.value,
                ...formData
            })
        })
        .then(() => window.location.href = '/contact/thanks/')
        .catch(error => alert(error));
    }

	render() {
		return (
            <form name="contact" method="post" onSubmit={this.submitHandler} className="form__container" action="/contact/thanks/" data-netlify-recaptcha="true" data-netlify="true">
                <input type="hidden" name="form-name" value="contact" />
                
                <label htmlFor="name" className="label">Name:</label>
                <FieldText
                    id="name"
                    name="name"
                    type={this.state.formControls.name.type}
                    placeholder={this.state.formControls.name.placeholder}
                    value={this.state.formControls.name.value}
                    onChange={this.changeHandler}
                    className={this.state.formControls.name.touched && !this.state.formControls.name.valid ? 'field__base field__text error' : 'field__base field__text'}
                />
                <FieldErrorMessage
                    className={this.state.formControls.name.touched && !this.state.formControls.name.valid ? 'show field__errors' : 'hide field__errors'}
                    errorMessages={this.state.formControls.name.errorMessages}
                />

                <label htmlFor="email" className="label">Email:</label>
                <FieldEmail
                    id="email"
                    name="email"
                    type={this.state.formControls.email.type}
                    placeholder={this.state.formControls.email.placeholder}
                    value={this.state.formControls.email.value}
                    onChange={this.changeHandler}
                    className={this.state.formControls.email.touched && !this.state.formControls.email.valid ? 'field__base field__email error' : 'field__base field__text'}
                />
                <FieldErrorMessage
                    className={this.state.formControls.email.touched && !this.state.formControls.email.valid ? 'show field__errors' : 'hide field__errors'}
                    errorMessages={this.state.formControls.email.errorMessages}
                />

                <label htmlFor="phone" className="label">Phone:</label>
                <FieldText
                    id="phone"
                    name="phone"
                    type={this.state.formControls.phone.type}
                    placeholder={this.state.formControls.phone.placeholder}
                    value={this.state.formControls.phone.value}
                    onChange={this.changeHandler}
                    className={this.state.formControls.phone.touched && !this.state.formControls.phone.valid ? 'field__base field__text error' : 'field__base field__text'}
                />
                <FieldErrorMessage
                    className={this.state.formControls.phone.touched && !this.state.formControls.phone.valid ? 'show field__errors' : 'hide field__errors'}
                    errorMessages={this.state.formControls.phone.errorMessages}
                />
                
                <label htmlFor="enquiry" className="label">Enquiry:</label>
                <FieldTextArea
                    id="enquiry"
                    name="enquiry"
                    type={this.state.formControls.enquiry.type}
                    placeholder={this.state.formControls.enquiry.placeholder}
                    value={this.state.formControls.enquiry.value}
                    onChange={this.changeHandler}
                    className={this.state.formControls.enquiry.touched && !this.state.formControls.enquiry.valid ? 'field__base field__textarea error' : 'field__base field__textarea'}
                />
                <FieldErrorMessage
                    className={this.state.formControls.enquiry.touched && !this.state.formControls.enquiry.valid ? 'show field__errors' : 'hide field__errors'}
                    errorMessages={this.state.formControls.enquiry.errorMessages}
                />

                <ReCAPTCHA
                    size="invisible"
                    ref={this.recaptchaRef}
                    sitekey={SITE_KEY}
                />

                <button type="submit"
                        className="btn btn__blue btn__small"
                        disabled={!this.state.formIsValid}
                >
                        Submit &rsaquo;
                </button>
            </form>
		)
	}
}